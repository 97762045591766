<template>
  <div @click="closeModal()">
    <admin-title></admin-title>

    <div class="container">
      <div class="page-title">
        <span>フロー設計</span>
      </div>


      <div class="content">
        <div class="c-left">
          <div :key="index" v-for="(item, index) in regularList" class="item-box">
            <div class="item-top" :style="{background: item.color}"></div>
            <div class="item-bottom">
              <div>

              </div>

              <p class="item-title">{{item.content}}</p>
              <p class="item-num">{{ item.totalCount }}</p>
            </div>
          </div>
        </div>
        <div class="c-right">
          <div class="category">
            <div v-for="(item, index) in entryFlowList" :key="index" :class="categoryIndex == index ? 'selected' : ''" @click="changeCategoryIndex(index)" class="item">
              <span @click.stop="confirmDeleteCategory(item)">×</span>
              <p>{{item.flowName}}</p>
              <el-dropdown placement="bottom" trigger="click">
                <img src="../../assets/images/processDesign/arrow_down.png"/>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="selectFlowNmSel(item, item2)" v-for="(item2) in flowNmSelList" :key="item2.kbCode">{{item2.kbCode}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <el-dropdown placement="bottom" trigger="click">
              <div class="add-category">
                ＋
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="addCategory(item2)" v-for="(item2) in flowNmSelList" :key="item2.kbCode">{{item2.kbCode}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
          <div class="box">
            <div class="box-content" ref="boxScroll">

              <draggable v-model="entryFlowDetails"  chosenClass="chosen" forceFallback="true" group="id" animation="1000" @start="onStart" @end="onEnd">
                <transition-group>
                  <div class="item-box" v-for="(item, index) in entryFlowDetails" :key="index">
                    <div :style="{background: item.color}" class="item-top"></div>
                    <div class="item-bottom">
                      <div>

                        <el-dropdown v-if="item.type == 2" placement="bottom-start" trigger="click">
                          <el-popover
                              class="box-color"
                              placement="bottom-start"
                              trigger="manual"
                              v-model="item.visible">
                            <div class="color-box">
                              <span @click="selectColor(item, index)" v-for="(color, index) in colors" :key="index" :style="{background: color}"></span>
                            </div>
                            <img src="../../assets/images/processDesign/dots.png" slot="reference">
                          </el-popover>

                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>-</el-dropdown-item>
                            <el-dropdown-item @click.native="modifyItem(item, 1)">ステータス名 変更</el-dropdown-item>
                            <el-dropdown-item @click.native="modifyItem(item, 2, index)">ステータス 削除</el-dropdown-item>
                            <el-dropdown-item @click.native="modifyItem(item, 3)">色変更</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>

                      <p v-show="!item.isNowAdd" class="item-title">{{item.content}}</p>
                      <el-input  v-show="item.isNowAdd" v-model="inputName" class="item-input" @click.native.stop="clickInput"  @blur="blurInput(item)" :autofocus="true"/>
                      <p class="item-num">{{item.totalCount}}</p>
                    </div>
                  </div>

                  <!--              <div class="item" v-for="element in myArray" :key="element.id">{{element.name}}</div>-->
                </transition-group>
              </draggable>


              <img v-if="entryFlowDetails.length < 10" @click="addDetail" class="add" src="../../assets/images/processDesign/add.png">

            </div>
          </div>
          <div class="save">
            <p @click="saveDetail">フローを保存</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
import {Message} from "element-ui";
export default {
  name: "processDesign",

  components: {
    draggable
  },

  data() {
    return {
      categoryIndex: 0,
      inputName: '',
      drag:false,
      //定义要被拖拽对象的数组
      myArray:[
        {people:'cn',id:1,name:'www.itxst.com'},
        {people:'cn',id:2,name:'www.baidu.com'},
        {people:'cn',id:3,name:'www.taobao.com'},
        {people:'us',id:4,name:'www.google.com'}
      ],

      visible:false,

      isNowAdd: false,

      colors: ['#8AD8E6', '#89D980', '#F2D15A', '#F7A2A2', '#888888'],

      entryFlowList: [],

      flowNmSelList: [],

      regularList: [],
      entryFlowDetails: [],
      userInfo: {},


      deleteId: []
    }
  },

  mounted() {
    this.init();
  },
  beforeRouteLeave(to, from, next) {
    this.$confirm('保存されていません。よろしいですか？', '', {
        confirmButtonText: 'はい',
        showCancelButton: 'いいえ',
        center: true,
        dangerouslyUseHTMLString: true
    }).then(() => {
      next();
    }).catch(() => {
    });
  },
  methods: {
    clickInput(e){

    },
    closeModal(){

      for(var i=0;i<this.entryFlowDetails.length;i++){
        this.entryFlowDetails[i].colorBox=false;
        this.entryFlowDetails[i].visible=false;
        this.entryFlowDetails[i].isNowAdd=false;
      }
      this.$forceUpdate();

    },
    init() {
      this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
      this.getEntryFlowList();
      this.getFlowNmSelList();
      this.getRegularList();

      // console.log(this.userInfo)
    },

    async getEntryFlowDetails() {
      // let res = await this.$axios({url: `/entryFlow/details`, data: {id: 1}, method: "post"});
      if(this.categoryIndex == -1) {
        this.entryFlowDetails = [];
        return;
      }
      let res = await this.$axios({url: `/entryFlow/details`, data: {id: this.entryFlowList[this.categoryIndex].id}, method: "post"});
      if(res.code == '000') {
        // res.data.forEach(item => {
        //   item.isNowAdd = false;
        // })
        if(res.data) {
          this.entryFlowDetails = res.data;
        } else {
          this.entryFlowDetails = [];
        }

      }
    },

    async getEntryFlowList() {
      //获取主流程数据列表
      const token = localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN);
      let res = await this.$axios({url: `/entryFlow/list`, data: {token: token}, method: "post"});
      if(res.code == '000') {
        this.entryFlowList = res.data;
        await this.getEntryFlowDetails();
      }
    },

    async getFlowNmSelList() {
      //获取主流程下拉列表
      let res = await this.$axios({url: `/common/flowNmSel`, data: {}, method: "post"});
      if(res.code == '000') {
        this.flowNmSelList = res.data;
      }
    },

    async selectFlowNmSel(item1, item2) {
      //选择下拉列表
      // console.log(item)
      let res = await this.$axios({url: `/entryFlow/update`, data: {
          'compId': this.userInfo.compId,
          'flowName': item2.kbName,
          "operatorId": this.userInfo.id,
          'id': item1.id
        }, method: "post"});
      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: res.message
        });
        await this.getEntryFlowList();

      }
    },

    addDetail() {
      let data = {
        color: this.colors[0],
        content: 'ステータス',
        mainId: this.entryFlowList[this.categoryIndex].id,
        type: 2,
        id: null
      };

      this.entryFlowDetails.push(data);

      this.$nextTick(() => {
        //获取完数据后，执行
        //判断内容小于一屏时：
        //let width = this.$refs.scrollRef.clientWidth;
        //判断内容超过一屏时：
        let width = this.$refs.boxScroll.scrollWidth;
        this.$refs.boxScroll.scrollTo(width,0);

      })
      // this.$forceUpdate()
    },

    selectColor(item, index) {
      item.color = this.colors[index];
      item.colorBox = false;
      item.visible = false;
      this.$forceUpdate();
    },

    modifyItem(item, type, index) {
      if(type == 1) {
        this.inputName = '';
        item.isNowAdd = true;
        // console.log(item)
      } else if(type == 2) {
        console.log(item)
        if(item.totalCount > 0) {


          this.$confirm('エントリー者がいるため<br/>ステータス削除ができません', '', {
            confirmButtonText: 'OK',
            dangerouslyUseHTMLString: true,
            showCancelButton:false,
            center: true
          }).then(() => {

          });

          return;
        }

        let that = this;
        this.$confirm('ステータスを削除してよろしいですか？', '', {
          confirmButtonText: 'はい',
          cancelButtonText: 'キャンセル',
          center: true
        }).then(() => {

          that.entryFlowDetails = that.entryFlowDetails.filter((item, index2) => {
            return index2 != index;
          })
          if(item.id != '' && item.id != null) {
            that.deleteId.push(item.id);
          }

        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });





        // this.$confirm('ステータスを削除してよろしいですか？', '', {
        //   confirmButtonText: 'はい',
        //   cancelButtonText: 'キャンセル',
        //   type: 'warning',
        //   center: true
        // }).then(() => {
        //   this.deleteDetail(item);
        //   // this.deleteCategory(item);
        // }).catch(() => {
        //   // this.$message({
        //   //   type: 'info',
        //   //   message: '已取消删除'
        //   // });
        // });
      } else if(type == 3) {
        item.colorBox = true;
        item.visible = true;
      }

      this.$forceUpdate();
    },

    blurInput(item) {
      // console.log("sss")
      item.isNowAdd = false;
      if(this.inputName != '' && this.inputName != null) {
        item.content = this.inputName;
      }
      this.inputName = '';
    },

    async deleteDetail() {
      for (let i = 0; i < this.deleteId.length; i++) {
        let res = await this.$axios({url: `/entryFlow/deleteDetail`, data: {"operatorId": this.userInfo.id, id: this.deleteId[i]}, method: "post"});
      }
      this.deleteId = [];
      // if(res.code == '000') {
      //   this.$message({
      //     type: 'success',
      //     message: res.message
      //   });
      //   await this.getEntryFlowDetails();
      //
      // }
    },

    async saveDetail() {
      await this.deleteDetail();

      this.entryFlowDetails.forEach((item, index) => {
        item.sequence = index + 1;
      })
      // console.log(this.entryFlowList)
      // return;
      let res = await this.$axios({url: `/entryFlow/updateDetails`, data: {
          "operatorId": this.userInfo.id,
          id: this.entryFlowList[this.categoryIndex].id || '',
          detailList: this.entryFlowDetails
        }, method: "post"});
      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: res.message
        });
        await this.getEntryFlowDetails();

      }
    },

    //获取固定列表
    async getRegularList() {
      let res = await this.$axios({url: `/entryFlow/sysDetails`, data: {"compId": this.userInfo.compId}, method: "post"});
      if(res.code == '000') {
        this.regularList = res.data;

      }
    },

    confirmDeleteCategory(item) {
      this.$confirm('フローを削除してよろしいですか？', '', {
        confirmButtonText: 'はい',
        cancelButtonText: 'キャンセル',

        center: true
      }).then(() => {

        this.deleteCategory(item);
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },

    async deleteCategory(item) {
      let res = await this.$axios({url: `/entryFlow/delete`, data: {"operatorId": this.userInfo.id, id: item.id}, method: "post"});
      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: res.message
        });

        if(this.entryFlowList.length == 1) {
          this.categoryIndex = -1;
        } else {
          this.categoryIndex = 0;
        }

        await this.getEntryFlowList();


      }

    },

    async addCategory(item) {

      // console.log(item)
      let res = await this.$axios({url: `/entryFlow/add`,
        data: {
          'compId': this.userInfo.compId,
          'flowName': item.kbName,
          "operatorId": this.userInfo.id
        },
        method: "post"});
      if(res.code == '000') {
        // Message({
        //   showClose: true,
        //   message: res.message,
        //   type: "success"
        // });
        await this.getEntryFlowList();
        this.categoryIndex = this.entryFlowList.length - 1;
        await this.getEntryFlowDetails();
      }
    },



    async changeCategoryIndex(index) {
      if(this.categoryIndex == index) return;
      this.categoryIndex = index;
      await this.getEntryFlowDetails();
    },

    //开始拖拽事件
    onStart(){
      this.drag=true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag=false;
      // console.log(this.entryFlowDetails)
    },
  }

}
</script>

<style scoped src="../../style/processDesign.css">

</style>
